import React from "react";

const experienceContent = [
  {
    year: "   2021 - Present",
    position: "Senior Software Engineer",
    compnayName: "Sanaa Finance",
    details: `  developing and optimizing user interfaces for web applications.`,
  },
  {
    year: "2020 - Present",
    position: " Software Engineer Frontend",
    compnayName: "Sanaa Brands",
    details: `developing and optimizing user interfaces for web applications. Proficient in translating design concepts into functional and interactive web pages, ensuring seamless integration with backend systems.`,
  },
  {
    year: "2018 - 2020",
    position: "Senior Frontend Developer",
    compnayName: "KING CEASOR UNIVERSITY",
    details: `Building and maintaining University digital infrastructure Building and maintaining University digital infrastructure
    `,
  },

  {
    year: "2019 - 2021",
    position: " Graphic designer ",
    compnayName: "Vietnam Consulate-Uganda",
    details: `Building and maintaining Consulate's digital infrastructure like the website, social media and more
    `,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
